<template>
  <v-col cols="12">
    <v-form ref="foliosForm">
      <base-card>
        <v-card-title>
          Folios
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                ref="FoliosDisponibles"
                v-model="dataFolios.FoliosDisponibles"
                label="Cantidad de folios disponibles"
                prepend-inner-icon="mdi-note-multiple"
                disabled
              />
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                ref="FoliosAlertar"
                v-model="dataFolios.FoliosAlertar"
                label="Alertar cuando falten (Folios)"
                prepend-inner-icon="mdi-note-multiple"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
              />

            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                ref="FoliosRepetirAlerta"
                v-model="dataFolios.FoliosRepetirAlerta"
                label="Repetir alerta cada (Folios)"
                prepend-inner-icon="mdi-reload"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="10"
            />
            <v-col
              cols="12"
              sm="2"
            >
              <v-btn
                class="ma-2"
                style="background-color:rgb(204 33 40);color:white !important"
                @click="validateFoliosData"
              >
                Guardar
              </v-btn>
            </v-col>
            <v-alert
              v-show="successAlert"
              type="success"
              text
            >
              {{ msgSuccessAlert }}
            </v-alert>
          </v-row>
        </v-card-text>
      </base-card>
    </v-form>
  </v-col>
</template>

<script>

export default {
  name: 'ConfigAlertsFolios',

  data() {
    return {
      dataFolios: {
        FoliosDisponibles: '',
        FoliosAlertar: '',
        FoliosRepetirAlerta: '',
      },
      errorMessages: '',
      formHasErrors: null,
      successAlert: false,
      msgSuccessAlert: 'Alerta para folios guardada exitosamente',
      formRules: [
        (v) => !!v || 'Este campo es requerido (Solo acepta números)',
        (v) => v > 0 || 'El valor del campo debe ser mayor a cero',
      ],
    }
  },

  computed: {
    foliosForm() {
      return {
        FoliosDisponibles: this.dataFolios.FoliosDisponibles,
        FoliosAlertar: this.dataFolios.FoliosAlertar,
        FoliosRepetirAlerta: this.dataFolios.FoliosRepetirAlerta,
      }
    },
    statusResponseFolios () {
      return this.$store.state.folios.statusResponse
    },
  },

  created () {
    this.getFoliosData()
  },

  watch: {
    '$store.state.folios.foliosData': {
      handler(data) {
        this.dataFolios.FoliosDisponibles = data.FoliosDisponibles
        this.dataFolios.FoliosAlertar = data.FoliosAlertar
        this.dataFolios.FoliosRepetirAlerta = data.FoliosRepetirAlerta
      },
      deep: true
    },
    formHasErrors () {
      if (this.formHasErrors === false) {
        this.sendFoliosData()
      }
    },
    statusResponseFolios () {
      if(this.statusResponseFolios === true) {
        this.generateAlertSuccess()
        this.formHasErrors = true
        this.$store.dispatch('folios/setStatusResponse', false)
      }
    },
  },

  methods: {
    async getFoliosData() {
      await this.$store.dispatch('folios/getFoliosData')
    },
    generateAlertSuccess() {
      this.successAlert = true
      this.$store.dispatch('folios/getFoliosData')
      this.clearForm()
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    validateFoliosData() {
      this.formHasErrors = false
      Object.keys(this.foliosForm).forEach((campo) => {
        if (!this.foliosForm[campo] || this.foliosForm[campo] < 0) this.formHasErrors = true
        this.$refs[campo].validate(true)
      })
    },
    sendFoliosData() {
      this.$store.dispatch('folios/updateFoliosData', {
        ...this.dataFolios
      })
    },
    clearForm () {
      this.$refs.foliosForm.reset()
    },
  }
}

</script>
